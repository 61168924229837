export const ui = (state = {}, action) => {
    switch (action.type) {
        case "UI": {
            return {
                category_parent: action.payload.category_parent,
                best_selling_products: action.payload.best_selling_products,
                brands : action.payload.brands ,
                contact_us: action.payload.contact_us,
                menu: action.payload.menu,
                new_products: action.payload.new_products,
                sections: action.payload.sections,
                special_offer_products: action.payload.special_offer_products,
                sliders: action.payload.sliders,
                articles: action.payload.articles,
                story: action.payload.story,
                diesel_generator: action.payload.diesel_generator,
                alternator: action.payload.alternator,
                game_fun: action.payload.game_fun,
                art: action.payload.art,
                engineering: action.payload.engineering,
                stationery: action.payload.stationery,
            }
        }
        default: {
            return state
        }
    }
}
