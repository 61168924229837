import jwtDecode from "jwt-decode";

const initialState = {
    token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
    user_id: '',
    name: '',
    lastname: '',
    email: '',
    phone: '',
    suspend: '',
    roles: []
}
export const login = (state = {}, action) => {
    switch (action.type) {
        case "CheckPhone": {
            if (action.payload.token)
                return {
                    CheckPhoneToken: action.payload.token,
                    CheckPhoneStatus: action.payload.status
                }
            else
                return {
                    CheckPhoneError: action.payload
                }
        }
        case "LOGIN": {
            let user;
            try {
                user = jwtDecode(action.payload);
                return {
                    ...initialState,
                    token: user.token,
                    user_id: user.user_id,
                    name: user.name,
                    lastname: user.lastname,
                    email: user.email,
                    phone: user.phone,
                    country_code: user.country_code,
                    photo: user.profile_photo_link,
                    suspend: user.suspend,
                    roles: user.role
                };
            } catch (e) {
                localStorage.removeItem("token");
                return state;
            }
        }
        case "ERROR": {
            return {
                Error: action.payload
            }
        }
        default: {
            return state
        }
    }
}
