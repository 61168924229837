export const cart = (state = {}, action) => {
    switch (action.type) {
        case "CART": {
            return {
                count: action.payload.count,
                price: action.payload.price,
            }
        }
        default: {
            return state
        }
    }
}
