import React from "react"

class SpinnerComponent extends React.Component {
    render() {
        return (
            <div
                className="fallback-spinner d-flex flex-column align-items-center justify-content-center m-auto vh-100">
                <div class="loader"></div>
                <h1>
                    لوازم تحریر ساحل
                </h1>
            </div>
        )
    }
}

export default SpinnerComponent
